// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*==================== Main styles ======================*/
html body .general-header,
html body .general-footer {
  font-family: Montserrat-Regular;
  font-size: 15px;
}

/*=======================================================*/
/*============= Materialize compatibility ===============*/
/* html body .g-header-nav__column,
html body .g-block-footer__nav {
    width: auto;
    height: inherit;
    line-height: inherit;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
}

html body .g-actions-header__link,
html body .g-actions-footer__link {
    line-height: 1.15;
    display: inline-block;
    padding: 0;
}

.general-header h1,
.general-header h2,
.general-header h3,
.general-header h4,
.general-header h5,
.general-header h6,
.general-footer h1,
.general-footer h2,
.general-footer h3,
.general-footer h4,
.general-footer h5,
.general-footer h6 {
    line-height: inherit;
} */
/*=======================================================*/`, "",{"version":3,"sources":["webpack://./src/components/Components.scss"],"names":[],"mappings":"AAAA,0DAAA;AAEA;;EAEI,+BAAA;EACA,eAAA;AAAJ;;AAGA,0DAAA;AAGA,0DAAA;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAAA;AAiCA,0DAAA","sourcesContent":["/*==================== Main styles ======================*/\r\n\r\nhtml body .general-header,\r\nhtml body .general-footer {\r\n    font-family: Montserrat-Regular;\r\n    font-size: 15px;\r\n}\r\n\r\n/*=======================================================*/\r\n\r\n\r\n/*============= Materialize compatibility ===============*/\r\n\r\n/* html body .g-header-nav__column,\r\nhtml body .g-block-footer__nav {\r\n    width: auto;\r\n    height: inherit;\r\n    line-height: inherit;\r\n    background-color: transparent;\r\n    -webkit-box-shadow: none;\r\n    box-shadow: none;\r\n    padding: 0;\r\n}\r\n\r\nhtml body .g-actions-header__link,\r\nhtml body .g-actions-footer__link {\r\n    line-height: 1.15;\r\n    display: inline-block;\r\n    padding: 0;\r\n}\r\n\r\n.general-header h1,\r\n.general-header h2,\r\n.general-header h3,\r\n.general-header h4,\r\n.general-header h5,\r\n.general-header h6,\r\n.general-footer h1,\r\n.general-footer h2,\r\n.general-footer h3,\r\n.general-footer h4,\r\n.general-footer h5,\r\n.general-footer h6 {\r\n    line-height: inherit;\r\n} */\r\n\r\n/*=======================================================*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
