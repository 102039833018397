// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*======================= Import ========================*/
/*===================== Variables =======================*/
/*------- GeneralHeader and GeneralFooter fonts ---------*/
/*-------------------------------------------------------*/
/*=======================================================*/
/*=======================================================*/
/*==================== Main styles ======================*/
.footer {
  flex: 1 0 auto;
  margin-top: 100px;
}

.footer-content {
  padding: 30px;
  background: radial-gradient(at top, rgba(70, 193, 255, 0.7), rgba(255, 0, 95, 0.5));
}

/*=======================================================*/`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss","webpack://./src/vars.scss"],"names":[],"mappings":"AAAA,0DAAA;ACAA,0DAAA;AAEA,0DAAA;AASA,0DAAA;AAEA,0DAAA;ADTA,0DAAA;AAGA,0DAAA;AAEA;EACI,cAAA;EACA,iBAAA;AADJ;;AAIA;EACI,aAAA;EACA,mFAAA;AADJ;;AAIA,0DAAA","sourcesContent":["/*======================= Import ========================*/\r\n\r\n@import '../../vars';\r\n\r\n/*=======================================================*/\r\n\r\n\r\n/*==================== Main styles ======================*/\r\n\r\n.footer {\r\n    flex: 1 0 auto;\r\n    margin-top: 100px;\r\n}\r\n\r\n.footer-content {\r\n    padding: 30px;\r\n    background: radial-gradient(at top, rgba(70, 193, 255, .70), rgba(255, 0, 95, .50));\r\n}\r\n\r\n/*=======================================================*/","/*===================== Variables =======================*/\r\n\r\n/*------- GeneralHeader and GeneralFooter fonts ---------*/\r\n\r\n$font-bold: Montserrat-Bold;\r\n$font-extra-light-italic: Montserrat-ExtraLightItalic;\r\n$font-extra-light: Montserrat-ExtraLight;\r\n$font-light: Montserrat-Light;\r\n$font-italic: Montserrat-Italic;\r\n$font-regular: Montserrat-Regular;\r\n\r\n/*-------------------------------------------------------*/\r\n\r\n/*=======================================================*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
